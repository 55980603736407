import { createTheming } from '@callstack/react-theme-provider'

export const themes = [
  {
    themeName: 'borderstudio',
    primaryColor: {
      color: '#414042',
    },
    primaryBackgroundColor: {
      backgroundColor: '#414042',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#557994',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#E3D5C8',
    },
  },
  {
    themeName: 'stinkdigital',
    primaryColor: {
      color: '#75241B',
    },
    primaryBackgroundColor: {
      backgroundColor: '#75241B',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#8A665C',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#B2D9BD',
    },
  },
  {
    themeName: 'mediamonks',
    primaryColor: {
      color: '#116366',
    },
    primaryBackgroundColor: {
      backgroundColor: '#116366',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#7D6650',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#D2E9E3',
    },
  },
  {
    themeName: 'edesign',
    primaryColor: {
      color: '#272727',
    },
    primaryBackgroundColor: {
      backgroundColor: '#272727',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#A33F1D',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#79DBB3',
    },
  },
  {
    themeName: 'bert',
    primaryColor: {
      color: '#1F2833',
    },
    primaryBackgroundColor: {
      backgroundColor: '#1F2833',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#306E57',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#7ED9D2',
    },
  },
  {
    themeName: 'julieflogeac',
    primaryColor: {
      color: '#36498A',
    },
    primaryBackgroundColor: {
      backgroundColor: '#36498A',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#801E0F',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#EFE1BA',
    },
  },
  {
    themeName: 'bryanjames',
    primaryColor: {
      color: '#1A1A1C',
    },
    primaryBackgroundColor: {
      backgroundColor: '#1A1A1C',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#6F6993',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#BAC2D9',
    },
  },
  {
    themeName: 'reedbe',
    primaryColor: {
      color: '#4F1B1C',
    },
    primaryBackgroundColor: {
      backgroundColor: '#4F1B1C',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#755E6E',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#BABABA',
    },
  },
  {
    themeName: 'changedigital',
    primaryColor: {
      color: '#37315D',
    },
    primaryBackgroundColor: {
      backgroundColor: '#37315D',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#326480',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#84CEEB',
    },
  },
  {
    themeName: 'smfb',
    primaryColor: {
      color: '#46344E',
    },
    primaryBackgroundColor: {
      backgroundColor: '#46344E',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#696270',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#C9C795',
    },
  },
  {
    themeName: 'burocratik',
    primaryColor: {
      color: '#014482',
    },
    primaryBackgroundColor: {
      backgroundColor: '#014482',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#0B8C86',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#E0B870',
    },
  },
  {
    themeName: 'googlebrandstudio',
    primaryColor: {
      color: '#5C1F18',
    },
    primaryBackgroundColor: {
      backgroundColor: '#5C1F18',
    },
    secondaryBackgroundColor: {
      backgroundColor: '#BC453A',
    },
    tertiaryBackgroundColor: {
      backgroundColor: '#A9BBD9',
    },
  },
  // {
  //   themeName: 'purple',
  //   primaryColor: {
  //     color: 'rgb(55, 49, 93)',
  //   },
  //   primaryBackgroundColor: {
  //     backgroundColor: 'rgb(55, 49, 93)',
  //   },
  //   secondaryBackgroundColor: {
  //     backgroundColor: 'rgb(111, 105, 147)',
  //   },
  //   tertiaryBackgroundColor: {
  //     backgroundColor: 'rgb(197, 192, 227)',
  //   },
  // },
  // {
  //   themeName: 'blue',
  //   primaryColor: {
  //     color: 'rgb(49, 64, 93)',
  //   },
  //   primaryBackgroundColor: {
  //     backgroundColor: 'rgb(49, 64, 93)',
  //   },
  //   secondaryBackgroundColor: {
  //     backgroundColor: 'rgb(105, 119, 147)',
  //   },
  //   tertiaryBackgroundColor: {
  //     backgroundColor: 'rgb(192, 204, 227)',
  //   },
  // },
  // {
  //   themeName: 'red',
  //   primaryColor: {
  //     color: 'rgb(93, 49, 49)',
  //   },
  //   primaryBackgroundColor: {
  //     backgroundColor: 'rgb(93, 49, 49)',
  //   },
  //   secondaryBackgroundColor: {
  //     backgroundColor: 'rgb(147, 105, 105)',
  //   },
  //   tertiaryBackgroundColor: {
  //     backgroundColor: 'rgb(227, 192, 192)',
  //   },
  // },
  // {
  //   themeName: 'orange',
  //   primaryColor: {
  //     color: 'rgb(93, 72, 49)',
  //   },
  //   primaryBackgroundColor: {
  //     backgroundColor: 'rgb(93, 72, 49)',
  //   },
  //   secondaryBackgroundColor: {
  //     backgroundColor: 'rgb(147, 127, 105)',
  //   },
  //   tertiaryBackgroundColor: {
  //     backgroundColor: 'rgb(227, 210, 192)',
  //   },
  // },
  // {
  //   themeName: 'turquoise',
  //   primaryColor: {
  //     color: 'rgb(49, 93, 83)',
  //   },
  //   primaryBackgroundColor: {
  //     backgroundColor: 'rgb(49, 93, 85)',
  //   },
  //   secondaryBackgroundColor: {
  //     backgroundColor: 'rgb(105, 147, 137)',
  //   },
  //   tertiaryBackgroundColor: {
  //     backgroundColor: 'rgb(192, 227, 219)',
  //   },
  // },
]

const { ThemeProvider, withTheme } = createTheming(themes.blue)

export { ThemeProvider, withTheme }
