import { createStore as reduxCreateStore } from 'redux'
import { themes } from '../components/Helpers/themes'

const initialState = { theme: themes[0] }

const reducer = (state, action) => {
  if (action.type === 'CHANGE_THEME') {
    let currentPosition = themes.indexOf(state.theme)
    return Object.assign({}, state, {
      theme:
        themes[currentPosition + 1 == themes.length ? 0 : currentPosition + 1],
    })
  }
  return state
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
