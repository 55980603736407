import React from 'react'
import Transition from '../components/transition'
import PropTypes from 'prop-types'

const Layout = props => {
  return <Transition location={props.location}>{props.children}</Transition>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
